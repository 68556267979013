import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import MainLayout from 'src/layouts/main';

import CompactLayout from 'src/layouts/compact';
import { SplashScreen } from 'src/components/loading-screen';
import Page500 from 'src/sections/error/500-view';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/homepage/homepage'));
const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [{ path: '/home', element: <HomePage /> }],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page404 /> },
    ],
  },
];
