import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import '@fontsource/urbanist'; // Ajoute la police Urbanist
import keycloak from 'src/utils/Keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <ReactKeycloakProvider authClient={keycloak} >
          <App />
        </ReactKeycloakProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
