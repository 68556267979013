import { lazy, Suspense } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthGuardKeycloak } from 'src/auth/guard/AuthGuardKeycloak';

import { mainRoutes, HomePage } from './main';
import { mainTwoRoutes } from './main-two';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/home',
      element: (
        <AuthGuardKeycloak>
          <MainLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </MainLayout>
        </AuthGuardKeycloak>
      ),
      children: [{ element: <HomePage />, index: true }],
    },

    // Main routes
    ...mainRoutes,

    // Main two Route
    ...mainTwoRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/', element: <Navigate to="/home" replace /> },
  ]);
}
