import { createSlice } from '@reduxjs/toolkit';
import { THomepageState } from 'src/types/homepage';

const initialState: THomepageState = {
  homepage: null,
  helloworld: null,
  link: null,
  headerAdvisor: null,
  advisor: [],
  isLoading: true,
  smartbar: false,
  pageData: false,
};

const homepage_data = createSlice({
  name: 'homepage_data',
  initialState,
  reducers: {
    //  START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    //  SET Homepage
    setHome(state, action) {
      state.isLoading = false;
      state.homepage = action.payload;
    },
    // update widgets
    updateHomeWidgets(state, action) {
      state.isLoading = false;
      if (state.homepage) {
        state.homepage.widgets = action.payload;
      }
    },
    // SET helloworld section
    setHelloworld(state, action) {
      state.isLoading = false;
      state.helloworld = action.payload;
    },
    // SET link section
    setLink(state, action) {
      state.isLoading = false;
      state.link = action.payload;
    },
    // SET header advisor section
    setHeaderAdvisor(state, action) {
      state.isLoading = false;
      state.headerAdvisor = action.payload;
    },
    // SET advisor section
    setAdvisor(state, action) {
      state.isLoading = false;
      state.advisor = action.payload;
    },
    // SET smartbar section
    setSmartbar(state, action) {
      state.isLoading = false;
      state.smartbar = action.payload;
    },
    // SET page data
    setPageData(state, action) {
      state.isLoading = false;
      state.pageData = action.payload;
    },
  },
});

// to root reducer
export default homepage_data.reducer;

// to action
export const {
  startLoading,
  setHeaderAdvisor,
  setHome,
  setHelloworld,
  updateHomeWidgets,
  setLink,
  setAdvisor,
  setSmartbar,
  setPageData,
} = homepage_data.actions;
