import { useMemo, useEffect, useReducer, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { keycloak, initialized } = useKeycloak();

  const initialize = useCallback(async () => {
    if (initialized) {
      if (keycloak.authenticated && isValidToken(keycloak.token)) {
        // console.log('sdfsd', keycloak);
        setSession(keycloak);
        const user = {
          ...keycloak.tokenParsed,
          accessToken: keycloak.token,
        };
        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        keycloak.login();
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Rafraîchir le jeton automatiquement
// Rafraîchir le jeton automatiquement
useEffect(() => {
  const refreshAccessToken = async () => {
    try {
      const refreshed = await keycloak.updateToken(5);
      console.log('test refreshed', refreshed)
      if (refreshed) {
        const user = {
          ...keycloak.tokenParsed,
          accessToken: keycloak.token,
        };
        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
        return refreshed;
      } 
      return null;
    } catch (err) {
      console.error('Failed to refresh token', err);
      keycloak.logout();
      return null;
    }
  };

  if (keycloak.authenticated) {
    const refreshInterval = setInterval(refreshAccessToken, 60000);

    return () => clearInterval(refreshInterval);
  }
  return clearInterval(setInterval(refreshAccessToken, 60000));
}, [keycloak, dispatch]);

  

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'keycloak',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
    }),
    [state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
