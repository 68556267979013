import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import searchReducer from './reducer/search-reducer';
import homeReducer from './reducer/homepage-reducer';
import locataireReducer from './reducer/locatairepage-reducer';
import widgetsReducer from './reducer/widgets-reducer';
import incidentReducer from './reducer/incident-reducer';
import residenceReducer from './reducer/residencepage-reducer';
import analyseReducer from './reducer/analysepage-reducer';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const SearchPersistConfig = {
  key: 'search',
  storage,
};

const HomePagesPersistConfig = {
  key: 'homepage_data',
  storage,
};

const LocatairePagesPersistConfig = {
  key: 'locatairepage_data',
  storage,
};
const ResidencePagesPersistConfig = {
  key: 'residencepage_data',
  storage,
};

const WidgetsPersistConfig = {
  key: 'widgets_data',
  storage,
};

const IncidentPersistConfig = {
  key: 'incident_data',
  storage,
};

const AnalysePersistConfig = {
  key: 'analysepage_data',
  storage,
};

export const rootReducer = combineReducers({
  searchReducer: persistReducer(SearchPersistConfig, searchReducer),
  homepageReducer: persistReducer(HomePagesPersistConfig, homeReducer),
  residencepageReducer: persistReducer(ResidencePagesPersistConfig, residenceReducer),
  analysepageReducer: persistReducer(AnalysePersistConfig, analyseReducer),

  incidentReducer: persistReducer(IncidentPersistConfig, incidentReducer),
  locatairepageReducer: persistReducer(LocatairePagesPersistConfig, locataireReducer),
  widgetsReducer: persistReducer(WidgetsPersistConfig, widgetsReducer),
});
