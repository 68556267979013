import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'src/auth/hooks';
import { useKeycloak } from '@react-keycloak/web';
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import { Tooltip } from '@mui/material';
import { setSession } from 'src/auth/context/keycloak/utils';
import { useRouter } from 'src/routes/hooks';

export default function AccountPopover() {
  const { user } = useAuthContext();
  const { keycloak } = useKeycloak();
  const router = useRouter();

  const handleLogout = async () => {

    try {
      router.push('/');
      // console.log("Nettoyage des caches");
      // localStorage.clear();
      await keycloak.logout();
      setSession(null);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography variant="caption" noWrap mr={1}>
        {user?.name}
      </Typography>
      <Tooltip title="Se déconnecter">
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={handleLogout}
          sx={{
            width: 40,
            height: 40,
            background: (theme) => alpha(theme.palette.grey[100], 0.08),
          }}
        >
          <Iconify icon="mdi:door" width={24} />
        </IconButton>
      </Tooltip>
    </>
  );
}
