import React, { useState, useEffect } from 'react';
import { m } from 'framer-motion';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Theme, SxProps } from '@mui/material/styles';
import { ForbiddenIllustration } from 'src/assets/illustrations';
import { varBounce, MotionContainer } from 'src/components/animate';
import { RouterLink } from 'src/routes/components';
import { Button } from '@mui/material';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  profile?: string[];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function RoleBasedGuard({ hasContent, profile, children, sx }: RoleBasedGuardProp) {
  const { user } = useAuthContext();
  const currentProfile = user?.profile; // propmngr ou helpdesk ;
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsChecking(false);
    }, 2000); // 5 seconds delay

    return () => clearTimeout(timer);
  }, []);

  if (isChecking) {
    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Vérification des autorisations...
        </Typography>
      </Container>
    );
  }

  if (typeof profile !== 'undefined' && !profile.includes(currentProfile)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Accès non autorisé
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Vous n&apos;avez pas l&apos;autorisation nécessaire pour accéder à cette page.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
        <Button component={RouterLink} href="/home" size="large" variant="contained">
          Retour à l&apos;accueil
        </Button>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
