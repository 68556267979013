import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_API_HOST; // process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const TEST_API_TOKEN = '';
export const TEST_API_HOST =
  'https://api-complaints-dev-ulis.56c8d5a124e44bf2bcba.westeurope.aksapp.io';

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.home.root; // as '/'

export const ULIS_ROOT_URL:string = process.env.REACT_APP_ULIS_ROOT_URL || '#';
export const A3D_ROOT_URL:string = process.env.REACT_APP_A3D_ROOT_URL || '#';
