import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useCallback, useState } from 'react';
import { useDebounce } from 'src/hooks/use-debounce';
import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import HeaderShadow from '../common/header-shadow';
import { HEADER } from '../config-layout';
import AccountPopover from '../common/account';

export default function Header() {
  const theme = useTheme();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          <AccountPopover />
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
