import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { bgGradient } from 'src/theme/css';

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      <LinearProgress
        color="inherit"
        sx={{
          width: 1,
          maxWidth: 360,
          ...bgGradient({
            direction: 'to right',
            startColor: `#4D1D82 10%, #6A1D82 15%, #7C1D82 20%, #8B1D82 25%, #8B1D82 27%, #931A78 31%, #9A176F 35%, #BB0A45 40%, #CF022B 57%, #DF3D16 70%, #E8600A 90%, #F07D00 100%`,
            endColor: `#4D1D82 60% `,
          }),
        }}
      />
    </Box>
  );
}
