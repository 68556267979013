import { createSlice } from '@reduxjs/toolkit';
import { TResidencepageState } from 'src/types/residencepage';

const initialState: TResidencepageState = {
  isLoading: true,
  residencepage: null,
  link: [],
  headerAdvisor: null,
  advisor: [],
  headerProperty: null,
  smartbar: true,
  pageData: null,
};

const residencepage_data = createSlice({
  name: 'residencepage_data',
  initialState,
  reducers: {
    //  START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    //  SET Residence page
    setResidencepage(state, action) {
      state.isLoading = false;
      state.residencepage = action.payload;
    },
    // SET link section
    setLink(state, action) {
      state.isLoading = false;
      state.link = action.payload;
    },
    // SET header Residence section
    setHeaderProperty(state, action) {
      state.isLoading = false;
      state.headerProperty = action.payload;
    },
    // SET page data
    setPageData(state, action) {
      state.isLoading = false;
      state.pageData = action.payload;
    },
    // SET header advisor section
    setHeaderAdvisor(state, action) {
      state.isLoading = false;
      state.headerAdvisor = action.payload;
    },
    // SET advisor section
    setAdvisor(state, action) {
      state.isLoading = false;
      state.advisor = action.payload;
    },
  },
});

// to root reducer
export default residencepage_data.reducer;

// to action
export const {
  startLoading,
  setPageData,
  setHeaderProperty,
  setResidencepage,
  setLink,
  setHeaderAdvisor,
  setAdvisor,
} = residencepage_data.actions;
