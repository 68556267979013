import React, { useState, useCallback, DragEvent, useMemo, forwardRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  DialogActions,
  CircularProgress,
  Toolbar,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useBoolean } from 'src/hooks/use-boolean';
import { bgGradient } from 'src/theme/css';
import PdfViewer from 'src/components/pdf-viewer';
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import { FileDropContext } from './file-drop-context';

const Transition = forwardRef<unknown, TransitionProps & { children: React.ReactElement }>(
  (props, ref) => <Slide direction="up" ref={ref} {...props} />
);

export const FileDropProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [file, setFile] = useState<File | null>(null);
  const isModalOpen = useBoolean();
  const [showContent, setShowContent] = useState<boolean>(false);

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        setFile(event.dataTransfer.files[0]);
        isModalOpen.onTrue();
      }
    },
    [isModalOpen]
  );

  const closeModal = useCallback(() => {
    isModalOpen.onFalse();
    setFile(null);
    setShowContent(false);
  }, [isModalOpen]);

  const contextValue = useMemo(
    () => ({
      file,
      setFile,
      closeModal,
    }),
    [file, closeModal]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (file) {
      timeout = setTimeout(() => {
        setShowContent(true);
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [file]);

  return (
    <FileDropContext.Provider value={contextValue}>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{ height: '100vh', width: 'calc(100vw-25px)' }} // Ensure it covers the entire viewport
      >
        {children}
        {file && (
          <Dialog
            fullWidth={showContent}
            maxWidth="lg"
            open={isModalOpen.value}
            TransitionComponent={Transition}
            onClose={closeModal}
          >
            {!showContent ? (
              <>
                <DialogTitle>Fichier détecté : {file.name}</DialogTitle>
                <DialogContent sx={{ color: 'text.secondary' }}>
                  <Stack
                    spacing={3}
                    direction="column"
                    justifyContent="center"
                    sx={{ py: 6, textAlign: 'center' }}
                  >
                    <Stack direction="column" alignItems="center">
                      <Typography>Analyse du document...</Typography>
                      <CircularProgress size={24} color="primary" />
                    </Stack>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={closeModal}>
                    Annuler
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle
                  sx={{
                    ...bgGradient({
                      direction: 'to right',
                      startColor: `#4D1D82 10%, #6A1D82 15%, #7C1D82 20%, #8B1D82 25%, #8B1D82 27%, #931A78 31%, #9A176F 35%, #BB0A45 40%, #CF022B 57%, #DF3D16 70%, #E8600A 90%, #F07D00 100%`,
                      endColor: `#4D1D82 60% `,
                    }),
                    pt: { xs: 10, md: 2 },
                    pb: { xs: 10, md: 2 },
                  }}
                >
                  <Toolbar>
                    <Typography
                      variant="h4"
                      sx={{ flex: 1, ml: 2, fontWeight: 12, color: 'white' }}
                    >
                      {file.name === 'ATTESTATION.pdf'
                        ? `Attestation d'assurance détecté`
                        : `DPE détecté`}
                    </Typography>

                    <Stack direction="row" spacing={3}>
                      <Button
                        autoFocus
                        color="inherit"
                        variant="outlined"
                        sx={{ color: 'white' }}
                        onClick={closeModal}
                      >
                        Annuler
                      </Button>
                    </Stack>
                  </Toolbar>
                </DialogTitle>
                <DialogContent
                  sx={{
                    ...bgGradient({
                      direction: 'to right',
                      startColor: `#4D1D82 10%, #6A1D82 15%, #7C1D82 20%, #8B1D82 25%, #8B1D82 27%, #931A78 31%, #9A176F 35%, #BB0A45 40%, #CF022B 57%, #DF3D16 70%, #E8600A 90%, #F07D00 100%`,
                      endColor: `#4D1D82 60% `,
                    }),
                    pt: { xs: 10, md: 2 },
                    pb: { xs: 10, md: 2 },
                  }}
                >
                  <FormControlLabel
                    label="Ne plus me demander. Mettre à jour automatiquement"
                    sx={{ color: 'white' }}
                    control={<Checkbox sx={{ color: 'white' }} size="medium" />}
                  />
                  <Paper
                    sx={{
                      p: 2,
                      bgcolor: 'Background.paper',
                      mt: 2,
                    }}
                  >
                    <Stack>
                      <Typography variant="body2">
                        Les données suivantes ont été détectées :
                      </Typography>
                      {file.name === 'ATTESTATION.pdf' ? (
                        <>
                          <Stack direction="row" spacing={3} sx={{mt:3, mb:2}}>
                            <Box sx={{ pt:1, alignItems: 'left', minWidth: '200px;' }}>
                              <Typography variant="body2">
                                <strong>Locataire :</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'left', width: '100%' }}>
                              <Select
                                label="M Vincent Potard"
                                sx={{
                                  width: "100%",
                                  height: "40px;"
                                }}
                                value="1"
                              >
                                <MenuItem key="1" value="1" selected>
                                  Vincent Potard
                                </MenuItem>
                              </Select>
                              <Typography variant="body2"><i>Valeur extraite du document : M Vincent Potard.</i></Typography>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing={3} sx={{ mb:2}}>
                            <Box sx={{ pt:1, alignItems: 'left', minWidth: '200px;' }}>
                              <Typography variant="body2">
                                <strong>Lot :</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'left', width: '100%' }}>
                              <Select
                                label="Résidence Les Lilas"
                                sx={{
                                  width: "100%",
                                  height: "40px;"
                                }}
                                value="1"
                              >
                                <MenuItem key="1" value="1" selected>
                                  Résidence Les Lilas
                                </MenuItem>
                              </Select>
                              <Typography variant="body2"><i>Valeur extraite du document : 44 RUE DES PINSONS 44240 LA CHAPELLE SUR ERDRE.</i></Typography>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing={3} sx={{mb:2}}>
                            <Box sx={{ pt:1, alignItems: 'left', minWidth: '200px;' }}>
                              <Typography variant="body2">
                                <strong>Attestation valable du :</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'left', width: '100%' }}>
                              <Input
                                sx={{
                                  width: "100px",
                                  height: "40px;"
                                }}
                                value="01/01/2024"
                              />
                              &nbsp;&nbsp;au&nbsp;&nbsp;
                              <Input
                                sx={{
                                  width: "100px",
                                  height: "40px;"
                                }}
                                value="31/12/2024"
                              />
                              <Typography variant="body2"><i>Valeur extraite du document : 01/01/2024 au 31/12/2024</i></Typography>

                            </Box>
                          </Stack>
                        </>
                      ) : (
                        <>
                          <Stack direction="row" spacing={3} sx={{mt:3, mb:2}}>
                            <Box sx={{ pt:1.5, alignItems: 'left', minWidth: '200px;' }}>
                              <Typography variant="body2">
                                <strong>Lot :</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ pt:1, alignItems: 'left', width: '100%' }}>
                              <Select
                                label="Appartement LILAS-01-LOT23"
                                sx={{
                                  width: "100%",
                                  height: "40px;"
                                }}
                                value="1"
                              >
                                <MenuItem key="1" value="1" selected>
                                  Appartement LILAS-01-LOT23
                                </MenuItem>
                              </Select>
                              <Typography variant="body2"><i>Valeur extraite du document : 6 rue du PHILLY 69280 STE CONSORCE</i></Typography>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing={3} sx={{mb:2}}>
                            <Box sx={{ pt:1, alignItems: 'left', minWidth: '200px;' }}>
                              <Typography variant="body2">
                                <strong>Classification :</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'left', width: '100%' }}>
                              <Select
                                label="Appartement LILAS-01-LOT23"
                                sx={{
                                  width: "100%",
                                  height: "40px;"
                                }}
                                value="3"
                              >
                                <MenuItem key="3" value="3" selected>
                                  D
                                </MenuItem>
                                <MenuItem key="2" value="2">
                                  A
                                </MenuItem>
                                <MenuItem key="1" value="1">
                                  E
                                </MenuItem>
                              </Select>
                              <Typography variant="body2"><i>Valeur extraite du document : D</i></Typography>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing={3} sx={{mb:2}}>
                            <Box sx={{ pt:1, alignItems: 'left', minWidth: '200px;' }}>
                              <Typography variant="body2">
                                <strong>Surface :</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'left', width: '100%' }}>
                              <Input
                                sx={{
                                  width: "100%",
                                  height: "40px;"
                                }}
                                value="130"
                              />
                              <Typography variant="body2"><i>Valeur extraite du document : 130m²</i></Typography>
                              <Typography variant="body2" color="error">
                                <i>La valeur extraite du document est de 130m², celle connue est de 100m² &nbsp;</i>
                                <Button size="small" variant="outlined" color="error">< input type="checkbox" /> Mettre à jour la valeur de référence</Button>
                              </Typography>

                            </Box>
                          </Stack>


                          <Stack direction="row" spacing={3} sx={{mb:2}}>
                            <Box sx={{ pt:1, alignItems: 'left', minWidth: '200px;' }}>
                              <Typography variant="body2">
                                <strong>Attestation valable du :</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'left', width: '100%' }}>
                              <Input
                                sx={{
                                  width: "100px",
                                  height: "40px;"
                                }}
                                value="06/04/2024"
                              />
                              &nbsp;&nbsp;au&nbsp;&nbsp;
                              <Input
                                sx={{
                                  width: "100px",
                                  height: "40px;"
                                }}
                                value="05/04/2034"
                              />
                              <Typography variant="body2"><i>Valeur extraite du document : 06/04/2024 au 05/04/2034</i></Typography>

                            </Box>
                          </Stack>
                        </>
                      )}
                      <Button sx={{ mt: 3 }}autoFocus size="small" variant="outlined" onClick={closeModal}>
                        Valider la création
                      </Button>
                    </Stack>
                  </Paper>
                  <Stack
                    sx={{
                      py: 12,
                      m: 'auto',
                      maxWidth: 900,
                      minHeight: '100vh',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PdfViewer fileUrl={URL.createObjectURL(file)} />
                  </Stack>
                </DialogContent>
              </>
            )}
          </Dialog>
        )}
      </div>
    </FileDropContext.Provider>
  );
};
