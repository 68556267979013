import axios from 'src/utils/axios';
import keycloakServices from 'src/utils/Keycloak';

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken: string | undefined): boolean => {
  if (!accessToken) return false;
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const tokenExpired = (expiresIn: number, keycloak: any) => {
  if (expiresIn > 60) {
    setTimeout(
      () => {
        keycloak
          .updateToken(30) // Attempt to refresh the token 30 seconds before it expires
          .then((refreshed: any) => {
            if (refreshed) {
              console.log('Token successfully refreshed');
              setSession(keycloak); // Update session with new token
            } else {
              console.log('Token is still valid, no need to refresh');
            }
          })
          .catch((error: any) => {
            console.error('Failed to refresh token:', error);
            alert('Failed to refresh token');
            sessionStorage.removeItem('accessToken');
            keycloak.logout();
          });
      },
      (expiresIn - 60) * 1000
    );
  }
};

export const setSession = (keycloak: any) => {
  const { token } = keycloak;
  if (token) {
    sessionStorage.setItem('accessToken', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    // const decoded = jwtDecode(token);
    // const expiresIn = decoded.exp - Date.now() / 1000;
    // tokenExpired(expiresIn, keycloak);
  } else {
    // console.log("Nettoyage des caches");
    // localStorage.clear();
    keycloak.logout();
    sessionStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
