// ----------------------------------------------------------------------

const ROOTS = {
  HOMEPAGE: '/home',
  SEARCHRESULTSPAGE: '/search',
  INCIDENTPAGE: '/incident',
  APP: '/display',
};

// ----------------------------------------------------------------------

export const paths = {
  // HOMEPAGE
  home: {
    root: ROOTS.HOMEPAGE,
  },
  resultPage: {
    root: (title: string) => `${ROOTS.SEARCHRESULTSPAGE}/${title}`,
  },
  appPage: {
    root: ROOTS.APP,
    locataire: (idLocataire: string) => `${ROOTS.APP}/locataire/${idLocataire}`,
    resident: (idResident: string) => `${ROOTS.APP}/property/${idResident}`,
    analyse: `${ROOTS.APP}/analyseIncidentsResidence/144-PPA-0001`,
    embeded: (target: string) => `${ROOTS.APP}/embeded/${target}`,
  }
};
