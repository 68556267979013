import { createSlice } from '@reduxjs/toolkit';
import { TLocatairepageState } from 'src/types/locatairepage';

const initialState: TLocatairepageState = {
  isLoading: true,
  locatairepage: null,
  link: [],
  headerAdvisor: null,
  advisor: [],
  headerLocataire: null,
  requestButton: null,
  smartbar: true,
  pageData: null,
};

const locatairepage_data = createSlice({
  name: 'locatairepage_data',
  initialState,
  reducers: {
    //  START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    //  SET locatairepage
    setLocatairepage(state, action) {
      state.isLoading = false;
      state.locatairepage = action.payload;
    },
    // SET link section
    setLink(state, action) {
      state.isLoading = false;
      state.link = action.payload;
    },
    // SET header locataire section
    setHeaderLocataire(state, action) {
      state.isLoading = false;
      state.headerLocataire = action.payload;
    },
    // SET header advisor section
    setRequestButton(state, action) {
      state.isLoading = false;
      state.requestButton = action.payload;
    },
    // SET page data
    setPageData(state, action) {
      state.isLoading = false;
      state.pageData = action.payload;
    },
    // SET header advisor section
    setHeaderAdvisor(state, action) {
      state.isLoading = false;
      state.headerAdvisor = action.payload;
    },
    // SET advisor section
    setAdvisor(state, action) {
      state.isLoading = false;
      state.advisor = action.payload;
    },
  },
});

// to root reducer
export default locatairepage_data.reducer;

// to action
export const {
  startLoading,
  setPageData,
  setRequestButton,
  setHeaderLocataire,
  setLocatairepage,
  setLink,
  setHeaderAdvisor,
  setAdvisor,
} = locatairepage_data.actions;
