// Utility function to normalize strings and remove accents
export const normalizeString = (str: string) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

// comparison of to string
export const compareStrings = (str1: string, str2: string) =>
  normalizeString(str1) === normalizeString(str2);

// change first string element to uppercase
export function capitalizeFirstLetter(str: string): string {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function generateRandomString(): string {
  let randomString = '';

  for (let i = 0; i < 15; i += 1) {
    randomString += Math.floor(Math.random() * 10).toString();
  }

  return randomString;
}
