import { createSlice } from '@reduxjs/toolkit';
import { TWidgetState } from 'src/types/widgets';

const initialState: TWidgetState = {
  pages_widget_list: [],
  isLoading: true,
};

const widgets_data = createSlice({
  name: 'widgets_data',
  initialState,
  reducers: {
    //  START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    //  SET Page Widgets
    setPageWidget(state, action) {
      state.isLoading = false;
      state.pages_widget_list = action.payload;
    },
    // remove widget from list
    removeWidget(state, action) {
      state.isLoading = false;
      state.pages_widget_list = state.pages_widget_list.filter(widget => widget.id !== action.payload);
    },
  },
});

// to root reducer
export default widgets_data.reducer;

// to action
export const { startLoading, setPageWidget, removeWidget } = widgets_data.actions;
