import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { useMemo, useCallback } from 'react';

import { useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Box, Link, Stack, MenuItem, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter, usePathname } from 'src/routes/hooks';

import { generateRandomString } from 'src/utils/transform-data';

import { useDispatch, useSelector } from 'src/redux/store';
import { searchAction } from 'src/redux/actions/search-actions';
import {
  setSearchIndex,
  setSearchValue,
  getSelectedSuggestion,
} from 'src/redux/reducer/search-reducer';

import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';
import { RHFSearchSelect } from 'src/components/hook-form/rhf-select';
import FormProvider, { RHFAutocomplete } from 'src/components/hook-form';

import { THighlightWords, TSuggestionResult } from 'src/types/search';

type Props = {
  query?: string;
  results: TSuggestionResult[];
  loading?: boolean;
  customWidth?: number;
};

interface FormValuesProps {
  searchElement: string;
  searchCategory: string;
}

const TenantIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
  </SvgIcon>
);

export default function SearchBarComponent({ query, results, loading, customWidth }: Props) {
  const theme = useTheme();
  const router = useRouter();
  const dispatch = useDispatch();
  const pathName = usePathname();
  const { isLoading, searchValue, searchIndex } = useSelector((state) => state.searchReducer);

  const searchCategory = [
    {
      index: 'all',
      label: 'Toutes catégories',
    },
    {
      index: 'tenant',
      label: 'Locataire',
    },
    {
      index: 'property',
      label: 'Résidence',
    },
    {
      index: 'request',
      label: 'Affaire',
    },
    {
      index: 'document',
      label: 'Document',
    },
    /* {
      index: 'supplier',
      label: '',
    }, */
  ];

  const defaultValues = useMemo(
    () => ({
      searchElement: searchValue,
      searchCategory: 'all',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (query) {
      if (event.key === 'Enter') {
        dispatch(setSearchValue(query));
      }
    }
  };

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        dispatch(searchAction(searchValue, searchIndex));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, searchValue, searchIndex]
  );

  const setIndex = useCallback(
    async (index: any) => {
      try {
        if (index === 'all') {
          dispatch(setSearchIndex(undefined));
        } else {
          dispatch(setSearchIndex(index));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const onSearch = useCallback(
    async (data: TSuggestionResult) => {
      try {
        dispatch(searchAction(data.title, searchIndex));
        dispatch(getSelectedSuggestion(data));
        switch (data.index) {
          case 'tenant': {
            // locataire
            if (data.properties.tenantId) {
              console.log('sdfsd', paths.appPage.locataire(data.properties.tenantId[0]));
              router.push(paths.appPage.locataire(data.properties.tenantId[0]));
            }
            break;
          }
          case 'property': {
            // residence
            console.log('fff');
            router.push(paths.appPage.resident(data.title));
            break;
          }
          default: {
            break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, router, searchIndex]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((newValue: string) => {
        dispatch(searchAction(newValue, searchIndex));
      }, 500), // 500ms de délai
    [dispatch, searchIndex]
  );

  const highlightWord = (text: string, highlight: THighlightWords): string => {
    let boldDescription = text;

    if (highlight?.description) {
      highlight.description.forEach((word) => {
        boldDescription = boldDescription.replace(word, `<strong>${word}</strong>`);
      });
    }

    return boldDescription;
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ flexGrow: 1 }}>
        {pathName !== '/home' && (
          <Link variant="caption" href="#" sx={{ color: 'black' }}>
            <Stack direction="row" mt={1} onClick={() => router.back()}>
              <Iconify icon="material-symbols-light:arrow-back-ios" />
              Retour
            </Stack>
          </Link>
        )}
        <Stack direction={{ xs: 'row', md: 'row' }} alignItems="center" sx={{ flexGrow: 1 }}>
          <RHFSearchSelect
            name="searchCategory"
            size="small"
            InputLabelProps={{ shrink: true }}
            sx={{
              width: 250,
              height: 40,
              top: 0,
              color: 'red',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2e2e2e',
                borderTopRightRadius: 1,
                borderBottomRightRadius: 1,
                borderRight: 0,
              },
            }}
            PaperPropsSx={{ textTransform: 'capitalize' }}
          >
            {searchCategory.map((option) => (
              <MenuItem
                key={option.index}
                value={option.index}
                onClick={() => setIndex(option.index)}
              >
                {option.label}
              </MenuItem>
            ))}
          </RHFSearchSelect>
          <RHFAutocomplete
            name="searchElement"
            placeholder="Tapez votre recherche"
            fullWidth
            size="small"
            // onKeyUp={handleKeyUp}
            loading={isLoading}
            onInputChange={(event, newValue) => debouncedSearch(newValue)}
            noOptionsText={<SearchNotFound query={searchValue} sx={{ bgcolor: 'unset' }} />}
            options={results.map((r) => r.title)}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            filterOptions={(options, state) => options}
            sx={{
              height: 40,
              '& .MuiInputBase-root': {
                height: 39,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderTopLeftRadius: 1,
                borderBottomLeftRadius: 1,
              },
            }}
            renderOption={(props, option, { inputValue }) => {
              const searchElement = results.filter((r) => r.title === option)[0];

              if (!searchElement.title) {
                return null;
              }

              return (
                <li {...props} key={`${searchElement.title}-${generateRandomString()}`}>
                  <Stack width={1} direction="row" onClick={() => onSearch(searchElement)}>
                    {searchElement.index === 'tenant' && (
                      <Iconify icon="mdi:user" sx={{ height: 18, lineHeight: 18, mt: 0.2 }} />
                    )}
                    {searchElement.index === 'property' && (
                      <Iconify icon="mdi:home" sx={{ height: 18, lineHeight: 18, mt: 0.2 }} />
                    )}
                    {searchElement.index === 'document' && (
                      <Iconify icon="mdi:file" sx={{ height: 18, lineHeight: 18, mt: 0.2 }} />
                    )}
                    {searchElement.index !== 'document' &&
                      searchElement.index !== 'property' &&
                      searchElement.index !== 'tenant' && (
                        <Iconify icon="mdi:gear" sx={{ height: 18, lineHeight: 18, mt: 0.2 }} />
                      )}
                    <Box component="div" key={inputValue}>
                      {/* TODO à reprendre ASAP */}
                      <Typography
                        variant="caption"
                        dangerouslySetInnerHTML={{
                          __html: highlightWord(searchElement.title, searchElement.highlightWords),
                        }}
                      />
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* TODO à reprendre ASAP */}
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[600]}
                      dangerouslySetInnerHTML={{
                        __html: highlightWord(
                          searchElement.description,
                          searchElement.highlightWords
                        ),
                      }}
                    />
                  </Stack>
                </li>
              );
            }}
          />
        </Stack>
      </Stack>
    </FormProvider>
  );
}
