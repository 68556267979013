import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
import { ULIS_ROOT_URL, A3D_ROOT_URL } from 'src/config-global';

export default function Footer() {
  const pathname = usePathname();

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant="caption" component="div">
          © 2024 Sopra Steria - Tous droits réservés
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Link component={RouterLink} href={A3D_ROOT_URL} color="purple" variant="caption"
              target="_blank" rel="noopener noreferrer">
          Active 3D
        </Link>
        <Typography variant="caption" color="purple" component="div" m={0.5}>
          /
        </Typography>
        <Link component={RouterLink} ml={0.5} href={ULIS_ROOT_URL} color="purple" variant="caption"
              target="_blank" rel="noopener noreferrer">
          Ulis
        </Link>
      </Container>
    </Box>
  );
}
