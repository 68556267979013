import path from 'path';
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import MainLayoutTwo from 'src/layouts/main-two';
import { LoadingScreen } from 'src/components/loading-screen';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const LocatairePage = lazy(() => import('src/pages/locatairepage/locatairepage'));
const ResidentPage = lazy(() => import('src/pages/residencepage/residence'));
const EmbededPage = lazy(() => import('src/pages/embededpage/embededpage'));
const AnalysePage = lazy(() => import('src/pages/analysePage/analysepage'));

// ----------------------------------------------------------------------

export const mainTwoRoutes = [
  {
    path: '/display',
    element: (
      <MainLayoutTwo>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MainLayoutTwo>
    ),
    children: [
      {
        path: 'locataire/:id',
        element: (
          <RoleBasedGuard hasContent profile={['helpdesk']} sx={{ py: 10 }}>
            <LocatairePage />
          </RoleBasedGuard>
        ),
        index: true,
      },
      {
        path: 'property/:id',
        element: (
          <RoleBasedGuard hasContent profile={['propmngr']} sx={{ py: 10 }}>
            <ResidentPage />
          </RoleBasedGuard>
        ),
        index: true,
      },
      {
        path: 'analyseIncidentsResidence/144-PPA-0001',
        element: (
          <RoleBasedGuard hasContent profile={['propmngr']} sx={{ py: 10 }}>
            <AnalysePage />
          </RoleBasedGuard>
        ),
        index: true,
      },
      {
        path: 'embeded/:target',
        element: <EmbededPage />,
        index: true,
      },
    ],
  },
];
