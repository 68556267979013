import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
import { RouterLink } from 'src/routes/components';
import { useDispatch } from 'src/redux/store';
import { setSearchValue } from 'src/redux/reducer/search-reducer';
import Image from '../image';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  sizeWidth?: number;
  sizeHeight?: number;
  maxSizeWidth?: number;
  maxSizeHeight?: number;
  logoSrc?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      disabledLink = false,
      sizeWidth,
      sizeHeight,
      maxSizeHeight,
      maxSizeWidth,
      logoSrc,
      sx,
      ...other
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const handleClick = async () => {
      dispatch(setSearchValue(''));
    };

    const logo = (
      <Box
        ref={ref}
        onClick={() => handleClick()}
        component="div"
        sx={{
          width: sizeWidth ?? 355,
          height: sizeHeight ?? 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <Image
          disabledEffect
          visibleByDefault
          alt="overview"
          src={logoSrc ?? '/logo/logo_single.svg'}
          sx={{ maxWidth: maxSizeWidth ?? 540, maxHeight: maxSizeHeight ?? 40 }}
        />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/home" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
