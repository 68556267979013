import { createSlice } from '@reduxjs/toolkit';
import { TSuggestionState } from 'src/types/search';

const initialState: TSuggestionState = {
  suggestionResult: [],
  IndexList: [],
  selectedSuggestion: null,
  searchValue: '',
  searchIndex: '',
  isLoading: true,
};

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    //  START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    //  GET suggestion Result
    getSuggestionResult(state, action) {
      state.isLoading = false;
      state.suggestionResult = action.payload;
    },
    //  SET choose element
    getSelectedSuggestion(state, action) {
      state.isLoading = false;
      state.selectedSuggestion = action.payload;
    },
    //
    setSearchValue(state, action) {
      state.isLoading = false;
      state.searchValue = action.payload;
    },
    //
    setSearchIndex(state, action) {
      state.isLoading = false;
      state.searchIndex = action.payload;
    },
    //
    setSearchIndexList(state, action) {
      state.isLoading = false;
      state.IndexList = action.payload;
    },
  },
});

// to root reducer
export default search.reducer;

// to action
export const {
  startLoading,
  setSearchIndex,
  getSelectedSuggestion,
  getSuggestionResult,
  setSearchValue,
  setSearchIndexList,
} = search.actions;
