import { ReactNode } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { LoadingScreen } from 'src/components/loading-screen';

type AuthGuardProps = {
  children: ReactNode;
};

export function AuthGuardKeycloak({ children }: AuthGuardProps) {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <LoadingScreen />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  return <>{children}</>;
}
