
type Props = {
  fileUrl: any;
};

export default function PdfViewer({ fileUrl }: Props) {

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <iframe
                  title="PDF Viewer"
                  src={fileUrl}
                  style={{ width: '100%', height: 'calc(100vh - 200px)' }} // Adjust height as needed
                />
    </div>
  );
}
