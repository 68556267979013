import { createSlice } from '@reduxjs/toolkit';
import { TIncidentState } from 'src/types/incident';



const initialState: TIncidentState = {
  isLoading: true,
  newIncident: {
    activeStep: 1,
    num_incident: null,
    selectedlocataire: null,
    incidentReason: null,
    checkstep: [],
    confirmIncident: [],
    questionResolveIncident: null ,
    componentName: null ,
  },
};

const incident_data = createSlice({
  name: 'incident_data',
  initialState,
  reducers: {
    //  START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    /*   ---------------------------  NEW INCIDENT PART ------------------ */
    backStep(state) {
      state.newIncident.activeStep -= 1;
    },
    nextStep(state) {
      state.newIncident.activeStep += 1;
    },
    gotoStep(state, action) {
      state.newIncident.activeStep = action.payload;
    },
    //  START LOADING
    setNumIncident(state, action) {
      state.newIncident.num_incident = action.payload;
    },
    selectedLocataire(state, action) {
      state.newIncident.selectedlocataire = action.payload;
    },
    setIncidentReason(state, action) {
      state.newIncident.incidentReason = action.payload;
    },
    setIncidentCheck(state, action) {
      state.newIncident.checkstep = action.payload;
    },
    setConfirmIncident(state, action) {
      state.newIncident.confirmIncident = action.payload;
    },
    setQuestion(state, action) {
      state.newIncident.questionResolveIncident = action.payload;
    },
    resetNewIncidentForm(state) {
      state.newIncident.activeStep = 1;
      state.newIncident.num_incident = null;
      state.newIncident.selectedlocataire = null;
      state.newIncident.incidentReason = null;
      state.newIncident.checkstep = [];
      state.newIncident.confirmIncident = [];
      state.newIncident.questionResolveIncident = null;
      state.newIncident.componentName = null;
    },
    openPinnedIncident(state, action) {
      state.newIncident.activeStep = action.payload.activeStep;
      state.newIncident.num_incident = action.payload.num_incident;
      state.newIncident.selectedlocataire = action.payload.selectedlocataire;
      state.newIncident.incidentReason = action.payload.incidentReason;
      state.newIncident.checkstep = action.payload.checkstep;
      state.newIncident.confirmIncident = action.payload.confirmIncident;
      state.newIncident.questionResolveIncident = action.payload.questionResolveIncident;
      state.newIncident.componentName = action.payload.componentName;
    },

    /*   ---------------------------  NEW INCIDENT PART ------------------ */
  },
});

// to root reducer
export default incident_data.reducer;

// to action
export const {
  startLoading,
  backStep,
  nextStep,
  gotoStep,
  selectedLocataire,
  setIncidentReason,
  setIncidentCheck,
  setConfirmIncident,
  setQuestion,
  resetNewIncidentForm,
  setNumIncident,
  openPinnedIncident
} = incident_data.actions;
