// @mui
import { Box, Container, Stack, useTheme } from '@mui/material';
import { m } from 'framer-motion';
// routes
import { usePathname } from 'src/routes/hooks';
import { MotionViewport, varFade } from 'src/components/animate';
import { useSelector } from 'src/redux/store';
import SearchBarComponent from '../common/search-bar';
import Footer from '../main/footer';
import Header from './header';
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MainLayoutTwo({ children }: Props) {
  const theme = useTheme();
  const pathname = usePathname();
  const { suggestionResult } = useSelector((state) => state.searchReducer);
  const isHome = pathname === '/home';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Stack sx={{ zIndex: 11, position: 'fixed', bgcolor: 'white', width: 1 }}>
        <Container
          component={MotionViewport}
          sx={{
            pt: { xs: 15, md: 15 },
            pb: { xs: 1.5, md: 5 },
          }}
        >
          <m.div variants={varFade().inDown}>
            <SearchBarComponent results={suggestionResult} />
          </m.div>
        </Container>
      </Stack>

      <Box
        component="main"
        sx={{
          bgcolor: '#F4F4F4',
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 0.5 },
          }),
          mt: { sm: 20, md: 25 },
        }}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  );
}
