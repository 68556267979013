import { Dispatch } from '@reduxjs/toolkit';
import { getIndexListService, getSearchValueService } from 'src/services/search-services';
import {
  startLoading,
  getSuggestionResult,
  setSearchValue,
  setSearchIndexList,
} from '../reducer/search-reducer';

export function searchAction(searchItem: string, index: string) {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const result = await getSearchValueService(searchItem, [index]);
      if (result.data.length > 0) {
        dispatch(getSuggestionResult(result.data));
        dispatch(setSearchValue(searchItem));
      } else {
        dispatch(getSuggestionResult([]));
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };
}

export function getIndexListAction() {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const res = await getIndexListService();
      const resultItem: any = res.data;
      if (!resultItem) {
        dispatch(setSearchIndexList([]));
        return;
      }

      dispatch(setSearchIndexList(resultItem));
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };
}
