import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
  },
  dataLocation: (url: string) => `${url}`,
  display: {
    // retourne la page correspondant au tag
    get_page: (tag: string) => `/display/pages?tag=${tag}`,
    // retourne une liste de String, chacune correspondant à un advisor de la page
    get_page_advice: (tag: string) => `/display/advices?tag=${tag}`,
    // retourne les informations à afficher pour une advice, en prenant en paramètre son nom (reçu de l'appel ci-dessus). Le retour a une balise properties avec à l'intérieur, title et texte, et suggestion pour ce qui est affiché sur le bouton. Il n'y a pas d'URL derrière le bouton.
    get_advice_by_name: (name: string) => `/display/advices/${name}`,
    // retourne les informations à afficher pour un component, en prenant en paramètre son nom (reçu de l'appel de /page). Le retour a une balise properties avec à l'intérieur, des propriétés dépendant du composant, différentes pour chaque. Il faudra "mapper" ces propriétés uniques pour chaque component selon son affichage, je n'ai pas trouvé plus "générique"
    get_component_by_name: (name: string, tag: string) => `/display/components/${name}?tag=${tag}`,
    // epingler une page ou un widget
    post_pin_page: '/display/widgets:pin-page',
    // dépingler un widget d'une page
    remove_pin_page: (id: string,) => `/display/widgets/${id}`
  },
  widgets: {
    get_all: '/widgets/tag',
    get_widget_data: (id_widget: string, user_id: string, idType:string) =>
      `/widget-data/?${idType}=${user_id}&widgetId=${id_widget}`,
    // `/widget-data/?${idType}=${user_id}&widgetId=${id_widget}`,
    update_widgets_positions: '/display/widgets:update-positions'
  },
  searchbar: {
    //  get the first 10 suggestions
    get: (query: string, index?: string[]) =>
      index ? `/suggestions/search?q=${query}&index=${index}` : `/suggestions/search?q=${query}`,
    //  advanced search, search on index
    post: (query: string, index: string) => `/suggestions/search?q=${query}&index=${index}`,
    //  get all index
    get_all_index: `/suggestions/index`,
    //  get index by index
    get_index: (index: string) => `/suggestions/index/${index}`,
  },
};
