import { createSlice } from '@reduxjs/toolkit';
import { TAnalysepageState } from 'src/types/analyse';

const initialState: TAnalysepageState = {
  isLoading: true,
  analysepage:null
};

const analysepage_data = createSlice({
  name: 'analysepage_data',
  initialState,
  reducers: {
    //  START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    //  SET Analyse
    setAnalyse(state, action) {
      state.isLoading = false;
      state.analysepage = action.payload;
    },
  },
});

// to root reducer
export default analysepage_data.reducer;

// to action
export const {
  startLoading,
  setAnalyse
} = analysepage_data.actions;
