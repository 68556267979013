import axiosInstance, { endpoints } from '../utils/axios';

// Get search value first 10 suggested value
export const getSearchValueService = async (seachValue: string, index?: string[]) => {
  const response = await axiosInstance.get(endpoints.searchbar.get(seachValue, index));
  return response;
};

// Get index list
export const getIndexListService = async () => {
  const response = await axiosInstance.get(endpoints.searchbar.get_all_index);
  return response;
};
